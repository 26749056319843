<template>
  <div>
    <b-card>
      <h3>Panelimize Hoş Geldiniz</h3>
    </b-card>

    <b-card>
      <b-card-body>
        <vue-apex-charts
            type="line"
            height="400"
            :options="chartOptions"
            :series="_allSales.series"
        />
      </b-card-body>
    </b-card>

    <button @click="throwError">Throw error</button>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {mapGetters} from 'vuex'

export default {
  name: 'HomePage',
  data: () => ({}),
  components: {
    VueApexCharts,
  },
  computed: {
    ...mapGetters('statistics', ['_allSales']),
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: this._allSales.xaxis,
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: ['#a4f8cd', '#60f2ca', '#2bdac7'],
      }
    },
  },
  mounted() {
    this.$store.dispatch('statistics/allSales')
  },
  methods: {
    throwError() {
      throw new Error('Sentry Error');
    }
  }
}
</script>

<style>

</style>
